<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">Contact Us</h1>
        </div>
    </div>
</div>
<div class="container single-page mb-5">
    <div class="row">
        <div class="col-md-6 py-5">
            <img src="assets/images/location.png" style="width:30px;" class="float-start me-4">
            <h5 class="mt-1" [innerHTML]="content.location"></h5> <br>
            <img src="assets/images/mail.png" style="width:30px;" class="float-start me-4">
            <h5 class="mt-1" [innerHTML]="content.email"></h5> <br>
            <i class="me-2 fa-solid fa-headphones-simple float-start me-4" style="color: #896dff;font-size: 30px;"></i>
            <h5 class="mt-1" [innerHTML]="content.number"></h5> <br>
            <div class="app-icons">
                <h3 class="my-4">Get The App - Get Started</h3>
                <a href="https://play.google.com/store/apps/details?id=com.vowpay.vowpay" target="_blank"><img src="assets/images/googleplay_white.png"></a>
                <span class="mx-2"></span>
                <a href="https://apps.apple.com/us/app/vowpay/id6739277893" target="blank"><img src="assets/images/appstore_white.png"></a>
            </div>
        </div>
        <div class="col-md-6 py-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.215555334951!2d-0.5461040234969768!3d51.50926127181367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876713056dce6d3%3A0x4fd0189307d66878!2sThames%20Water!5e0!3m2!1sen!2sin!4v1733234958787!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</div>