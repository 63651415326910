import { Component } from '@angular/core';

@Component({
  selector: 'app-signupemailnotverified',
  templateUrl: './signupemailnotverified.component.html',
  styleUrls: ['./signupemailnotverified.component.css']
})
export class SignupemailnotverifiedComponent {

}
