import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { ErrorComponent } from '../error/error.component';
import { ApiResponse } from '../models/apiresponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Utilities } from '../shared/Utilities';

@Component({
  selector: 'app-signupemailverify',
  templateUrl: './signupemailverify.component.html'
})


export class SignupemailverifyComponent implements OnInit, AfterViewInit {
  public loading = false;
  isBrowser: boolean;
  paramId: any;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, public dialog: MatDialog,  @Inject(PLATFORM_ID) platformId: Object) { 
                this.isBrowser = isPlatformBrowser(platformId);
              }

  ngOnInit() {
    this.loading = true;
    this.route.queryParams
      .subscribe(
        (params: Params) => {
          this.paramId = params['id'];
          this.verifyEmail(this.paramId);
        });
  }

  verifyEmail (paramId: any): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {

        this.loading = true;

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers , withCredentials: true};
        const payload = { id: paramId, systemId: environment.systemId };
        const endpoint = '/orm/usermanagement/verifyemail';
        const endPointUrl = environment.apiProtocol + '://' + environment.apiServerIP + ':' + environment.apiServerPort + endpoint;
        const params = { requestTimestamp: Utilities.encryptPayLoad(new Date().toString(), environment.clientKey).toString(), payload: payload };
        this.http.post(endPointUrl, JSON.stringify(params), options)
          .toPromise()
          .then(response => {
            const jsonResponse = response;
            const apiResponse: ApiResponse = new ApiResponse(null, null);
            apiResponse.result = jsonResponse;
            resolve(apiResponse);
            this.loading = false;
            if (apiResponse.result.err) {
              this.router.navigate(['/customers/signupemailnotverified'], { queryParams: { id: paramId } });
              return;
            }
            if (apiResponse.result) {
              this.router.navigate(['/customers/signupemailverified']);
            }
          })
          .catch((apiException: ApiResponse) => {
            this.loading = false;
            this.dialog.open(ErrorComponent, {
              data: {
                message: 'Some error occur.Please try again'
              }
            });
          });
    });
  }

  ngAfterViewInit() {

  }
  
}
