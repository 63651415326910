import { Component } from '@angular/core';

@Component({
  selector: 'app-resetpassworddone',
  templateUrl: './resetpassworddone.component.html',
  styleUrls: ['./resetpassworddone.component.css']
})
export class ResetpassworddoneComponent {

}
