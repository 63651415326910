<div>
    <h1 matDialogTitle>Failure</h1>
  
    <mat-dialog-content>
        {{data.message}}
    </mat-dialog-content>
  
    <mat-dialog-actions>
        <button mat-raised-button matDialogClose>Close</button>
    </mat-dialog-actions>
  </div>
  