<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">Reset Password</h1>
        </div>
    </div>
</div>
<mat-spinner class="mx-auto" *ngIf="loading"></mat-spinner>
<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading> -->
<div class="container single-page mb-5 ">
    <div class="row">
        <div class="col-md-4 border mx-auto py-5 mt-5">
            <form name="resetPasswordForm" id="resetPasswordForm" class="form-horizontal" [formGroup]="form"
                *ngIf="form" novalidate autocomplete="off" (ngSubmit)="resetPassword()">
                <div class="form-group">
                    <label class="control-label col-md-6" h5>New Password:
                        <span class="asteriskField">*</span>
                    </label>
                    <div class="controls col-md-12">
                        <input class="form-control" id="newPassword" name="newPassword" type="password"
                            formControlName="newPassword" />
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-md-6" h5>Confirm Password:
                        <span class="asteriskField">*</span>
                    </label>
                    <div class="controls col-md-12">
                        <input class="form-control" id="confirmPassword" name="confirmPassword" type="password"
                            formControlName="confirmPassword" />
                    </div>
                </div>

                <div>
                    <small class="text-muted">- Your password must be minimum 8 characters.</small> <br>
                    <small class="text-muted">- Your password must contain at least one uppercase.</small> <br>
                    <small class="text-muted">- Your password must contain at least one lowercase.</small> <br>
                    <small class="text-muted">- Your password must contain at least one number.</small>
                </div>

                <div class="form-group">
                    <div class="controls Signup col-md-12">
                        <button class="btn btn-primary" type="submit" name="Updatepassword"
                            id="submit-id-changepassword" [disabled]="!form.valid || disableSubmitButton">
                            Update Password
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>