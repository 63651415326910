import { Component } from '@angular/core';

@Component({
  selector: 'app-signupemailverified',
  templateUrl: './signupemailverified.component.html',
  styleUrls: ['./signupemailverified.component.css']
})
export class SignupemailverifiedComponent {

}
