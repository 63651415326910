import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { HttpClientModule } from '@angular/common/http';
import { DeleteaccountComponent } from './deleteaccount/deleteaccount.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SignupemailverifyComponent } from './signupemailverify/signupemailverify.component';
import { SignupemailverifiedComponent } from './signupemailverified/signupemailverified.component';
import { SignupemailnotverifiedComponent } from './signupemailnotverified/signupemailnotverified.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ResetpassworddoneComponent } from './resetpassworddone/resetpassworddone.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './error/error.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    FaqComponent,
    TermsComponent,
    PrivacypolicyComponent,
    CookiepolicyComponent,
    DisclaimerComponent,
    DeleteaccountComponent,
    AboutusComponent,
    ContactUsComponent,
    SignupemailverifyComponent,
    SignupemailverifiedComponent,
    SignupemailnotverifiedComponent,
    ResetpasswordComponent,
    ResetpassworddoneComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
