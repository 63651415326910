import { FormControl, FormGroup } from "@angular/forms";

export class CustomValidators {

static matchingPasswords(password: string | number, confirmPassword: string | number) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[password];
      const passwordConfirmationInput = group.controls[confirmPassword];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalentPassword: true});
      }
    };
  }

  static validPasswordPattern(control: FormControl): any {
    const hasUpperCase = /[A-Z]/.test(control.value);
    const hasLowerCase = /[a-z]/.test(control.value);
    const hasNumberCase = /[0-9]/.test(control.value);
    const uppercaseMessage = {
      'noUppercase': {
        'message': 'Your password must contain at least one uppercase.'
      }
    };
    const lowercaseMessage = {
      'noLowercase': {
        'message': 'Your password must contain at least one lowercase.'
      }
    };
    const numbercaseMessage = {
      'noNumberCase': {
        'message': 'Your password must contain at least one number.'
      }
    };
    if (!hasUpperCase) {
      return uppercaseMessage;
    }
    if (!hasLowerCase) {
      return lowercaseMessage;
    }
    if (!hasNumberCase) {
      return numbercaseMessage;
    }
  }


}