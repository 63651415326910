<div class="container-fluid bg-light">
  <div class="row">
    <div class="col-md-12 mx-auto hOnePageTitle">
      <h1 class="p-4 text-center">FAQ's</h1>
    </div>
  </div>
</div>
<div class="container single-page my-5">
  <div class="row">
    <div class="col-md-12 pb-5">
      <h5>Need help with Vowpay and sending money abroad. Here are some FAQs which may help you.</h5><br>
      <div id="accordion">

        <div class="card">
          <div class="card-header" id="headingTwentyThree">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                Who can use Vowpay to Send Money?
              </button>
            </h6>
          </div>
          <div id="collapseTwentyThree" class="collapse show" aria-labelledby="headingTwentyThree"
            data-parent="#accordion">
            <div class="card-body">
              <p>Vowpay is now available for UK nationals and legal residents wishing to send money to their loved ones.
              </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingOne">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                How to Use the Vowpay App?
              </button>
            </h6>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
              <p>To send money, complete the registration on the mobile app by providing documents that confirm your
                identity and address. Once your identity is verified, enter the amount you wish to send, add a
                beneficiary, and click on Send Money. Get started by downloading the Vowpay app on the App Store or
                Google Play.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingThree">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                How can I pay for sending money abroad?
              </button>
            </h6>
          </div>
          <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              <p>You can use a debit card for payments. Vowpay utilises modern technology to facilitate seamless
                payments through our app.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingFour">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Which countries does Vowpay send money to?
              </button>
            </h6>
          </div>

          <div>
            <div id="collapseFour" class="collapse show" aria-labelledby="headingFour" data-parent="#accordion">
              <div class="card-body">
                <p>We provide remittance services for customers sending funds to East, Central, and West Africa, Asia,
                  the Middle East, and North America.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingFive">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Our Charges and Fees?
              </button>
            </h6>
          </div>
          <div id="collapseFive" class="collapse show" aria-labelledby="headingFive" data-parent="#accordion">
            <div class="card-body">
              <p>Our exchange rates and fees are available on our website and mobile app, ensuring you know the amount
                the beneficiary will receive and the cost to you.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingTwentytwo">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
                How Secure Is Vowpay?
              </button>
            </h6>
          </div>
          <div id="collapseTwentytwo" class="collapse show" aria-labelledby="headingTwentytwo" data-parent="#accordion">
            <div class="card-body">
              <p>Your personal and transactional data security is our priority. All your data is fully encrypted and
                secure.
              </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingTwentyThree">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                How long does it take to transfer money abroad?
              </button>
            </h6>
          </div>
          <div id="collapseTwentyThree" class="collapse show" aria-labelledby="headingTwentyThree"
            data-parent="#accordion">
            <div class="card-body">
              <p>After processing your transaction, we strive to ensure that your beneficiary receives their funds
                instantly. You can track the progress of the transfer using the app.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingTwentytwo">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
                What Methods of Fund Delivery Does Vowpay Offer?
              </button>
            </h6>
          </div>
          <div id="collapseTwentytwo" class="collapse show" aria-labelledby="headingTwentytwo" data-parent="#accordion">
            <div class="card-body">
              <p>Vowpay provides three methods for delivering funds to recipients based on their location, the amount,
                and available pay out options: </p>
              <ol>
                <li>Cash delivery</li>
                <li>Bank transfer</li>
                <li>Mobile money</li>
              </ol>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingTwentytwo">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
                Our Partners?
              </button>
            </h6>
          </div>
          <div id="collapseTwentytwo" class="collapse show" aria-labelledby="headingTwentytwo" data-parent="#accordion">
            <div class="card-body">
              <p>Vowpay has formed strategic partnerships with reputable payout firms, each offering robust payout capabilities across various jurisdictions. Our payout partners have seamless access to all transactions via our secure API, ensuring the swift and efficient processing of payments. Vowpay remains committed to expanding its network of trusted payout partners to continually enhance and optimize our payment facilitation services.</p>
              <p>Some of our valued partners include:</p>
              <ul>
                <li>Mpesa - Kenya</li>
                <li>Ebirr – Ethiopia</li>
                <li>United Bank Limited - Pakistan</li>
                <li>Yap - Pakistan & Bangladesh</li>
                <li>MTN – Uganda & Rwanda</li>
                <li>Sundus Exchange UAE & Qatar</li>
                <li>Sundus Pay – Turkeiye</li>
                <li>Korapay – West Africa</li>
                <li>Rasmi Pay - North America</li>
              </ul>
            </div>
          </div>
        </div>




      </div>
      <div class="text-center mt-5">
        <h2 class="my-4">Get The App - Get Started</h2>
        <a href="https://play.google.com/store/apps/details?id=com.vowpay.vowpay" target="_blank"><img src="assets/images/googleplay_white.png"></a>
        <span class="mx-2"></span>
        <a href="https://apps.apple.com/us/app/vowpay/id6739277893" target="blank"><img src="assets/images/appstore_white.png"></a>
      </div>
    </div>
  </div>
</div>