import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models/apiresponse';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  content = { 'pageTitle': '', 'pageContent': '' };
  contentResult: any;
  loading: boolean = false;
  constructor(private http: HttpClient) {

  }

  ngOnInit() {
    this.getSiteContent('aboutus');
  }

  getSiteContent(fileName: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const filePath = '/assets/content/' + fileName + '.json';
      const endPoint = window.location.origin + filePath;
      this.http.get(endPoint)
        .toPromise()
        .then(response => {
          const jsonResponse = response;
          const apiResponse: ApiResponse = new ApiResponse(null, null);
          apiResponse.result = jsonResponse;
          resolve(apiResponse);
          if (apiResponse.err) {
            console.log(apiResponse.err);
            return;
          }
          if (apiResponse.result) {
            this.contentResult = apiResponse.result.aboutUs;
            this.loading = false;
            this.content = this.contentResult.find((list: { sendingCountryId: number; }) => list.sendingCountryId == 245);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

}