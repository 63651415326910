import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from '../models/apiresponse';
import { MatDialog } from '@angular/material/dialog';
import { CustomValidators } from '../shared/customValidators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utilities } from '../shared/Utilities';
import { ErrorComponent } from '../error/error.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  public loading = false;
  form: FormGroup | undefined;
  id: string | undefined;
  disableSubmitButton = false;
  constructor(private route: ActivatedRoute, private fb: FormBuilder, private router: Router, private http: HttpClient,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
        this.form = this.fb.group({
          newPassword: [null, [Validators.required, Validators.minLength(8), CustomValidators.validPasswordPattern]],
          confirmPassword: [null, [Validators.required, Validators.minLength(8)]]
        }, { validator: CustomValidators.matchingPasswords('newPassword', 'confirmPassword') }
        );
      } else {
        this.dialog.open(ErrorComponent, {
          data: {
            message: 'Invalid data'
          }
        });
      }
    });
  }

  resetPassword(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      if (this.form?.valid) {

        this.disableSubmitButton = true;
        const formValues = this.form.value;
        const newPassword = formValues.newPassword;
        const id = this.id;
        this.loading = true;


        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers , withCredentials: true};
        const payload = { id: id, newPassword: newPassword, systemId: environment.systemId };
        const endpoint = '/orm/usermanagement/resetpassword';
        const endPointUrl = environment.apiProtocol + '://' + environment.apiServerIP + ':' + environment.apiServerPort + endpoint;
        const params = { requestTimestamp: Utilities.encryptPayLoad(new Date().toString(), environment.clientKey).toString(), payload: payload };

        this.http.post(endPointUrl, JSON.stringify(params), options)
          .toPromise()
          .then(response => {
            const jsonResponse = response;
            const apiResponse: ApiResponse = new ApiResponse(null, null);
            apiResponse.result = jsonResponse;
            resolve(apiResponse);
            this.loading = false;
            if (apiResponse.result.err) {
              this.disableSubmitButton = false;
              this.dialog.open(ErrorComponent, {
                data: {
                  message: apiResponse.result.err[0].message.toString()
                }
              });
              return;
            }
            if (apiResponse.result) {
              this.router.navigate(['/customers/resetpassworddone']);
            }
          })
          .catch((apiException: ApiResponse) => {
            this.loading = false;
            this.disableSubmitButton = true;
            this.dialog.open(ErrorComponent, {
              data: {
                message: (apiException && apiException.err && apiException.err.message) ? apiException.err.message.toString() : apiException
              }
            });
          });
      }
    });
  }
}
