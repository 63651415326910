<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">Privacy Policy</h1>
        </div>
    </div>
</div>
<div class="container single-page mb-5">
    <div class="row">
        <div class="col-md-12 py-5">
            <!-- <div [innerHTML]="content.pageContent"></div> -->
            <p>Vowpay Limited understands that your privacy is important to you and that you care about how your
                personal data is used. We respect and value the privacy of all our customers and will only collect and
                use personal data in ways that are described here, and in a way that is consistent with our obligations
                and your rights under the law.</p>
            <h5>1. Information About Us</h5>
            <p>Vowpay Limited. <br><br>
                Private Limited Company registered in England under company number <strong>09200297</strong><br><br>
                <strong>Registered address:</strong> Link Spaces, Suite # 02, Link Spaces, Botanica Ditton Park, Riding
                Court Road, Slough, SL3 9LL. <br><br>
                <strong>Main trading address:</strong> Link Spaces, Suite # 02, Link Spaces, Botanica Ditton Park,
                Riding Court Road, Slough, SL3 9LL. <br><br>
                <strong>Data Protection Officer:</strong> Azhar William <br><br>
                <strong>Email address:</strong> <a href='mailto:compliance@vowpay.com'>compliance@vowpay.com</a><br><br>
                <strong>Postal address:</strong> Vowpay Limited, Link Spaces, Suite # 02, Link Spaces, Botanica Ditton
                Park, Riding Court Road, Slough, SL3 9LL. <br><br>
                We are regulated by the Financial Conduct Authority (FCA) and the Information Commissioners Office
                (ICO).
            </p>
            <h5>2. What Does This Notice Cover?</h5>
            <p>This Privacy Information explains how we use your personal data: how it is collected, how it is held, and
                how it is processed. It also explains your rights under the law relating to your personal data.</p>
            <h5>3. What Is Personal Data?</h5>
            <p>Personal data is defined by the UK GDPR and the Data Protection Act 2018 (collectively, “the Data
                Protection Legislation”) as ‘any information relating to an identifiable person who can be directly or
                indirectly identified in particular by reference to an identifier’.</p>
            <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal
                data covers obvious information such as your name and contact details, but it also covers less obvious
                information such as identification numbers, electronic location data, and other online identifiers.</p>
            <p>The personal data that we use is set out in Part 5, below.</p>
            <h5>4. What Are My Rights?</h5>
            <p>Under the Data Protection Legislation, you have the following rights, which we will always work to
                uphold:</p>
            <ol>
                <li>Privacy Notice should tell you everything you need to know, but you can always contact us to find
                    out more or to ask any questions using the details in Part 11.</li>
                <li>The right to access the personal data we hold about you. Part 10 will tell you how to do this.</li>
                <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate
                    or incomplete. Please contact us using the details in Part 11 to find out more.</li>
                <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your
                    personal data that we hold. Please contact us using the details in Part 11 to find out more.</li>
                <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                <li>The right to withdraw consent. This means that, if we are relying on your consent as the legal basis
                    for using your personal data, you are free to withdraw that consent at any time.</li>
                <li>The right to data portability. This means that, if you have provided personal data to us directly,
                    we are using it with your consent or for the performance of a contract, and that data is processed
                    using automated means, you can ask us for a copy of that personal data to re-use with another
                    service or business in many cases.</li>
                <li>Rights relating to automated decision-making and profiling. We do not generally use your personal
                    data in this way, Part 6 explains more about how we use your personal data, including any automated
                    decision-making or profiling.</li>
            </ol>
            <p>For more information about our use of your personal data or exercising your rights as outlined above,
                please contact us using the details provided in Part 11.</p>
            <p>It is important that your personal data is kept accurate and up to date. If any of the personal data we
                hold about you changes, please keep us informed as long as we have that data.</p>
            <p>Further information about your rights can also be obtained from the Information</p>
            <p>Commissioner’s Office (ICO) or your local Citizens Advice Bureau.</p>
            <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a
                complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve your
                concerns ourselves, however, so please contact us first, using the details in Part 11.</p>
            <h5>5. What Personal Data Do Vowpay Collect and How?</h5>
            <p>We collect and hold some or all of the personal and non-personal data set out in the table below, using
                the methods also set out in the table. We do not collect any ‘special category’ or ‘sensitive’ personal
                data or personal data relating to children.</p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td class="bg-light fw-bold">Data Collected</td>
                        <td class="bg-light fw-bold">How We Collect the Data</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Identity Information including:</strong> your name, postal address, email address,
                            mobile number, and date of birth, citizenship, and government or national identification
                            numbers.
                        </td>
                        <td>
                            Through mobile application form and your interactions with Vowpay Limited.
                            Through mobile application or other services we offer (some of which may be managed by third
                            parties on behalf of Vowpay Limited. <br><br>

                            While providing customer support or consultation, using email, post, call centres or Over
                            The Top i.e. WhatsApp, iMessage, Facebook or SMS. <br><br>

                            Through the process of maintaining and upgrading our services;
                            Through automated means such as communications protocols, e-mail communications and cookies;
                            <br><br>

                            Through our mobile applications (some of which may be managed or have functionalities
                            provided by third parties on behalf of Vowpay Limited. <br><br>
                            Through your use of social media or other public data sources; and <br><br>

                            Through your interest in Vowpay Limited advertisements placed on third party sites.

                        </td>
                    </tr>
                    <tr>
                        <td><strong>Contact information</strong> including: your name, postal address, email address,
                            mobile number.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td><strong>Business information</strong> including; how you use of our Services, bank and
                            credit information.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td><strong>Payment information including:</strong> e.g. card details, bank account numbers,
                            transaction information, receipts, including your money transfers.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td><strong>Profile information</strong> including; e.g. consents and permissions to contact or
                            marketing choices.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td><strong>Data from third parties including:</strong> e.g. contact information and profile
                            information.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td><strong>Technical information</strong>, including: the host from which you access the
                            Internet, your IP address or device advertising ID, geolocation if enabled, information
                            about your device (such as device properties, settings, applications, storage and usage
                            information), mobile application, the date and time you access our mobile application. We
                            collect personal information on your Vowpay mobile application activities.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td><strong>Compliance information</strong>, including; Anti-money laundering (AML) due
                            diligence information, fraud prevention, identity verification, credit referencing,
                            politically exposed persons (PEP) and sanction screening and checks.</td>
                        <td>As Above</td>
                    </tr>
                </tbody>

            </table>
            <h5>6. How Does Vowpay Use My Personal Data?</h5>
            <p>Under the Data Protection Legislation, we must always have a lawful basis for using your personal data.
                The following table describes how we will use your personal data, and the lawful basis for doing so.</p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td class="bg-light fw-bold">What We Do</td>
                        <td class="bg-light fw-bold">What Data We Use</td>
                        <td class="bg-light fw-bold">Our Lawful Basis</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Vowpay Limited utilises the personal information it collects to: <br><br>
                            Assist us in providing products and services to you and help you to receive a personalised
                            experience and assist you in carrying out transactions and accessing account
                            information.<br><br>

                            To recognise you and allow you to sign-in whilst using our services.<br><br>

                            To perform analytics on understanding our customers, improving their experience; To deliver
                            information specific to your interests.<br><br>
                            To better understand the effectiveness of our promotional campaigns and whether you acted on
                            our promotional messages;<br><br>
                            To promote safety, reduce financial risk and combat fraud across our products and services.


                        </td>
                        <td>
                            Identity Information including, your name, postal address, email address, mobile number, and
                            date of birth, citizenship and government or national identification numbers;<br><br>

                            Contact information including, your name, postal address, email address, mobile number;
                            Business information including, how you use of our products and services, bank and credit
                            information;<br><br>

                            Payment information including; e.g. card details, bank account numbers, transaction
                            information, including your money transfers.<br><br>

                            Profile information, including; e.g. Consents and permissions to contact or marketing
                            choices;

                            Data from third parties, including: e.g. contact information, profile information.<br><br>
                            Technical information, including: the host from which you access the Internet, your IP
                            address or device advertising ID, geolocation if enabled, information about your device
                            (such as device properties, settings, applications, storage and usage information), mobile
                            application, the date and time you access our mobile application. We collect personal
                            information on your Vowpay mobile application activities.<br><br>
                            Compliance information, including; Anti-money laundering (AML) due diligence information,
                            fraud prevention, identity verification, credit referencing, politically exposed persons
                            (PEP) and sanction screening and checks.
                        </td>
                        <td>
                            <p>For legal and compliance purposes: This includes using personal information to comply
                                with our legal and regulatory obligations to the global financial system in relation to
                                anti-money laundering and counter-terrorist financing; sanctions; detection, prevention
                                and prosecution of fraud and theft, as well as preventing illegitimate or prohibited use
                                of our services or other illegal or wrongful activity. This may also include
                                establishing, exercising, or defending legal rights and claims of Vowpay Limited and
                                others, and monitoring and reporting compliance issues.
                                This may further include using your personal information to validate and authenticate
                                your identity and utilising third parties to help us do so.
                            </p>
                            <p>For legitimate business purposes: This includes using personal information to analyse and
                                improve our products, locations, services, operations, the running of the mobile
                                application and your customer experience, and for measuring marketing return on
                                investment and brand satisfaction. This may include using your information to conduct
                                market research surveys. We may also use your personal information to provide customer
                                services and to help protect the security and integrity of our technology, systems, and
                                services.</p>
                            <p>Based on your consent: We may also use your information on this basis. At the time of
                                entering into a transaction with us you are consensually providing us with your
                                information. This includes consent for receiving marketing communications, or where
                                otherwise required by applicable law. If we request your consent, we will inform you of
                                the intended purposes for which your information will be processed.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            We use internet technologies like cookies and web beacons. <br><br>
                            <p>Vowpay Limited has implemented the capability for the user to manage cookies on our
                                website(s). We will only read or write cookies based on your preference level. (Note:
                                cookies set prior to you changing your preference level are maintained on your computer;
                                to remove them please refer to your browser settings and, if needed, your
                                browser’s “Help” function). If your browser does not support JavaScript, you can find
                                out about the cookies used on our sites and can only manage the use of cookies through
                                your web browser settings. Please note that if you disable all cookies on your browser,
                                this will limit the features of this site and may impact the ability to complete your
                                transaction.
                            </p>
                        </td>
                        <td>
                            To assist us in providing services to you and help you to receive the best experience using
                            this mobile application;<br><br>

                            To allow you to navigate the mobile application during your visit without having to re-enter
                            your password;
                            To track activity on our mobile application;<br><br>

                            To verify your identity when you use our mobile applications, and we may contact your mobile
                            carrier to assist us in verifying your identity;<br><br>

                            To deliver information specific to your interests.<br><br>

                            To better understand the effectiveness of our promotional campaigns and whether you acted on
                            our promotional messages;
                            To identify you when you visit the mobile application, to personalise the content of the
                            mobile application for you and to assist you in carrying out transactions and accessing
                            account information.

                        </td>
                        <td>As Above.</td>
                    </tr>
                </tbody>
            </table>
            <p>With your permission and as permitted by law, we may use your personal data for marketing purposes, which
                may include contacting you by using email, post, call centres or Over The Top i.e. WhatsApp, iMessage,
                Facebook or SMS with information, news, and offers on our products and services. You will not be sent
                any unlawful marketing or spam. We will always work to fully protect your rights and comply with our
                obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC
                Directive) Regulations 2003, and you will always have the opportunity to opt-out. We will always obtain
                your express opt-in consent before sharing your personal data with third parties for marketing purposes
                and you will be able to opt-out at any time.
            </p>
            <h6>Automated decision-making</h6>
            <p>We do not generally use your personal data in any automated decision-making or profiling.</p>
            <p>We will only use your personal data for the purpose(s) for which it was originally collected unless we
                reasonably believe that another purpose is compatible with that or those original purpose(s) and need to
                use your personal data for that purpose. If we do use your personal data in this way and you wish us to
                explain how the new purpose is compatible with the original, please contact us using the details in Part
                11.</p>
            <p>If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the
                purpose(s) for which it was originally collected, we will inform you and explain the legal basis which
                allows us to do so.</p>
            <p>In some circumstances, where permitted or required by law, we may process your personal data without your
                knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and
                your legal rights.</p>
            <h5>7. How Long Will You Keep My Personal Data?</h5>
            <p>We will not keep your personal data for any longer than is necessary in light of the reason(s) for which
                it was first collected. Your personal data will therefore be kept for the following periods (or, where
                there is no fixed period, the following factors will be used to determine how long it is kept):</p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td class="bg-light fw-bold">Type of Data</td>
                        <td class="bg-light fw-bold">How Long We Keep It</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Identity Information including: your name, postal address, email address, mobile number, and
                            date of birth, citizenship, and government or national identification numbers.</td>
                        <td>Vowpay Limited will keep personal data for five years following the completion of the
                            transaction or from the end of the business relationship. Personal information will be
                            destroyed after the five years has expired unless: <br>
                            - Vowpay is required to retain it under statutory obligation, (e.g. tax purposes) or <br>
                            - Vowpay is required to retain it for legal proceedings, (e.g. civil or criminal
                            proceedings) or <br>
                            - You have consented to the retention for example: by agreeing to this in our terms and
                            conditions.
                        </td>
                    </tr>
                    <tr>
                        <td>Contact information including: your name, postal address, email address, mobile number.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td>Business information including; how you use of our Services, bank and credit information.
                        </td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td>Payment information including: e.g. card details, bank account numbers, transaction
                            information, receipts, including your money transfers.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td>Profile information including; e.g. consents and permissions to contact or marketing
                            choices.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td>Data from third parties including: e.g., contact information, profile information.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td>Technical information, including: the host from which you access the Internet, your IP
                            address or device advertising ID, geolocation if enabled, information about your device
                            (such as device properties, settings, applications, storage and usage information), mobile
                            application, the date and time you access our mobile application. We collect personal
                            information on your Vowpay mobile application activities.</td>
                        <td>As Above</td>
                    </tr>
                    <tr>
                        <td>Compliance information, including; Anti-money laundering (AML) due diligence information,
                            fraud prevention, identity verification, credit referencing, politically exposed persons
                            (PEP) and sanction screening and checks.</td>
                        <td>As Above</td>
                    </tr>
                </tbody>
            </table>
            <p>Your personal information will be retained in accordance with statutory periods contained in regulations
                applicable to financial transactions including those in anti-money laundering, anti- terrorist financing
                and other laws applicable to us. Otherwise, we will retain your information only if necessary for the
                specific purposes it was collected, or to resolve any query you may raise. We will typically keep your
                information for no longer than the purpose(s) for which it was obtained, unless we have a legal
                obligation to keep it for a longer period or retention is advisable for Vowpay Limited’s legal position
                (for example, regarding applicable statutes of limitations, litigation or regulatory investigations).
            </p>
            <p>We are thus mandated by law to keep your information over a range of 5 to 10 years in our various
                jurisdictions after a given transaction, unless we have a legal obligation to keep it for a longer
                period.</p>
            <h5>8. How and Where Do Vowpay Store or Transfer My Personal Data?</h5>
            <p>We may store some of your personal data in the UK. This means that it will be fully protected under the
                Data Protection Legislation.</p>
            <strong>AND</strong>
            <p>We may store some of your personal data within the European Economic Area (the “EEA”). The EEA consists
                of all EU member states, plus Norway, Iceland, and Liechtenstein. This means that your personal data
                will be fully protected under the EU GDPR and/or to equivalent standards by law. Transfers of personal
                data to the EEA from the UK are permitted without additional safeguards.</p>
            <strong>AND</strong>
            <p>We may store some or all of your personal data in countries outside of the UK. These are known as “third
                countries”. We will take additional steps in order to ensure that your personal data is treated just as
                safely and securely as it would be within the UK and under the Data Protection Legislation as follows:
            </p>
            <strong>AND</strong>
            <p>We may store or transfer personal data in or to countries that are deemed to provide an adequate level of
                protection for personal data. For further information about adequacy decisions and adequacy regulations,
                please refer to the Information Commissioner’s Office.</p>
            <strong>AND</strong>

            <p>We may use specific approved contracts which ensure the same levels of personal data protection that
                apply under the Data Protection Legislation. For further information, please refer to the Information
                Commissioner’s Office.</p>

            <p>Please contact us using the details below in Part 11 for further information about the particular data
                protection safeguards used by us when transferring your personal data to a third country.</p>
            <p>The security of your personal data is essential to us, and to protect your data, we take a number of
                important measures, including the following:</p>
            <ul>
                <li>limiting access to your personal data to those employees, agents, contractors, and other third
                    parties with a legitimate need to know and ensuring that they are subject to duties of
                    confidentiality;</li>
                <li>procedures for dealing with data breaches (the accidental or unlawful destruction, loss, alteration,
                    unauthorised disclosure of, or access to, your personal data) including notifying you and/or the
                    Information Commissioner’s Office, where we are legally required to do so;</li>
            </ul>
            <p>We may transfer your information to parties in countries outside the EEA, as required by

                applicable law, regulatory authorities, law enforcement and government agencies. Additionally, when you
                send or receive money to or from another country, we will also be required to share some of your
                personal information with that country as required or permitted by law. We may transfer certain personal
                information about you to our processing units in countries outside the EEA and process it to fulfil our
                legal and compliance obligations which apply to the services we provide. We also store certain personal
                information we collect based on your marketing choices.
            </p>
            <p>Personal information transferred outside the EEA to countries that the European Commission has not found
                to provide adequate protection will be protected by appropriate contractual clauses or other approved
                mechanisms, as required by data protection legislation.</p>
            <h5>9. Does Vowpay Share My Personal Data?</h5>
            <p>We will not share any of your personal data with any third parties for any purposes, subject to the
                following exceptions:</p>
            <ol>
                <li>If we sell, transfer, or merge parts of our business or assets, your personal data may be
                    transferred to a third party. Any new owner of our business may continue to use your personal data
                    in the same way(s) that we have used it, as specified in this Privacy Policy.</li>
                <li>In some limited circumstances, we may be legally required to share certain personal data, which
                    might include yours, if we are involved in legal proceedings or complying with legal obligations, a
                    court order, or the instructions of a government authority.</li>
                <li>We may share your personal data with other companies in our group of companies for the provision of
                    products and services to you.</li>
                <li>We may sometimes contract with third parties to supply products and services.</li>
            </ol>
            <p>For any of the purposes listed in this statement, Vowpay Limited may disclose applicable personal
                information including, but not limited to, your name, contact information, national identification
                number, customer ID number, address, transaction patterns and bank account information to the following
                types of organisations or parties:</p>
            <ul>
                <li>Vowpay Limited (parent entity) group of companies and its affiliates;</li>
                <li>Required local PSD licensing entities relevant to a transaction;</li>
                <li>Our agents (if any) or business partners who facilitate the money transfer transaction or specific
                    payment services you have requested;</li>
                <li>Service providers, payments processors, banking partners and data processors contracted to provide
                    business and customer services;</li>
                <li>Customer services, based on your consent where applicable, including marketing, advertising and
                    customer satisfaction and market research on our behalf;</li>
                <li>Service providers and data processors contracted to help us validate the accuracy of the information
                    provided by you, and to authenticate your identity and manage risks related to security, fraud and
                    identity.</li>
                <li>Third parties that you have authorised to access your account and/or your account information in
                    order to perform services for you, such as account information service providers and payment
                    initiation service providers.</li>
            </ul>
            <p>We may transmit personal information we possess as required or permitted by applicable laws and
                regulations, to regulatory and financial authorities, law enforcement bodies, courts, governments or
                government agencies, to meet compliance and legal obligations or to assert or defend the rights and
                interests of Vowpay Limited or others.</p>
            <p>If any of your personal data is shared with a third party, as described above, we will take steps to
                ensure that your personal data is handled safely, securely, and in accordance with your rights, our
                obligations, and the third party’s obligations under the law, as described above in Part 8.</p>
            <p>If any personal data is transferred outside of the UK, we will take suitable steps in order to ensure
                that your personal data is treated just as safely and securely as it would be within the UK and under
                the Data Protection Legislation, as explained above in Part 8.</p>
            <h5>10. How Can I Access My Personal Data?</h5>
            <p>If you want to know what personal data we have about you, you can ask us for details of that personal
                data and for a copy of it (where any such personal data is held). This is known as a “subject access
                request”.</p>
            <p>There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded
                or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our
                administrative costs in responding.</p>
            <p>We will respond to your subject access request usually within 48 hours and, in any case, not more than
                one month of receiving it. Normally, we aim to provide a complete response, including a copy of your
                personal data within that time. In some cases, however, particularly if your request is more complex,
                more time may be required up to a maximum of three months from the date we receive your request.</p>
            <p>You will be kept fully informed of our progress.</p>
            <h5>11. How Do I Contact You?</h5>

            <p>
                To contact us about anything to do with your personal data and data protection, including to make a
                subject access request, please use the following details: <br><br>
                (For the attention of Azhar William:<br><br>
                <strong>Email address:</strong> <a href='mailto:compliance@vowpay.com'>compliance@vowpay.com</a><br><br>
                <strong>Postal Address:</strong> Azhar William<br><br>
                Head of Compliance / MLRO<br><br>
                Vowpay Limited <br>
                Link Spaces, Suite # 02, Link Spaces, Botanica Ditton Park, Riding Court Road, Slough, SL3 9LL.
            </p>
            <h5>12. Changes to this Privacy Notice</h5>
            <p>We may change this Privacy Notice from time to time. This may be necessary, for example, if the law
                changes, or if we change our business in a way that affects personal data protection.</p>
            <p>Any changes will be made available via our website. This Privacy Notice was last updated on 28 December,
                2024.</p>
        </div>
    </div>
</div>