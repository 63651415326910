<section class="footer" id="contactus">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 mr-auto col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<img src="assets/images/white-icon.png" alt="Vowpay" class="img-fluid mt-4">
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h5 class="widget-title">Legal Pages</h5>
					<ul class="list-unstyled footer-links">
						<!-- <li><a routerLink="/terms">Terms & Condition</a></li>
						<li><a routerLink="/privacypolicy">Privacy Policy</a></li> -->
						<li><a href="https://stg-vowpay-documents-public.s3.eu-west-2.amazonaws.com/Vowpay+Terms+and+Conditons+Final+080921.pdf" target="_blank">Terms & Condition</a></li>
						<li><a href="privacypolicy.html">Privacy Policy</a></li>
						<li><a routerLink="cookiepolicy">Cookie Policy</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h5 class="widget-title">Follow Us</h5>
					<ul class="list-inline footer-socials">
						<li class="list-item">
							<a href="#" target="_blank"> <i class="fab fa-facebook-f"></i>Facebook</a>
						</li>
						<li class="list-item py-2">
							<a href="#" target="_blank"> <i class="fab fa-instagram"></i>Instagram</a>
						</li>
						<!-- <li class="list-item">
							<a href="#"> <i class="fab fa-twitter"></i>Twitter</a>
						</li>
						<li class="list-item py-2">
							<a href="#"> <i class="fab fa-linkedin"></i>Linkedin</a>
						</li> -->
					</ul>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h5 class="widget-title">Location</h5>
					<p>Suite #02, Link Spaces, Botanica Ditton Park, Riding Court Road, Slough, United Kingdom, SL3 9LL.</p>
					<ul class="list-unstyled">
						<li>E : support@vowpay.com</li>
						<li>P : 020-3837-6655, 020-3879-1144</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="footer-btm">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="copyright">
					<p>@ 2018 - 2024 Copyright - Designed & Developed by <a href="http://tayseertech.com/" target="_blank">Tayseer Technologies</a></p>
				</div>
			</div>
		</div>
	</div>
</div>