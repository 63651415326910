<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">Success</h1>
        </div>
    </div>
</div>
<div class="container single-page mb-5">
    <div class="row py-5">
        <div class="col-md-12 py-5">
            <div class="col-md-12 mx-auto text-center">
              <div class="title-bar clearfix text-center my-3" id="title">
          <h3 >New password has been set successfully!!</h3>
        </div>
        <p >Thank you for using Vowpay. <br> Use Mobile App to Login and start using Vowpay services. </p>
        </div>
        </div>
    </div>
</div>